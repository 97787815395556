.title {
    color: #132433;
    leading-trim: both;
    text-edge: cap;
    font-family: Urbanist;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 64.62px;
    /* 161.549% */
    letter-spacing: 1.026px;
}

.img-preview {
    overflow: hidden;
    border-radius: 7.049px;
}

.img-preview>img {
    height: 100%;
    width: 100%;
}

.program-card {
    border-radius: 12px;
    background: #D5D5FF !important;
    border: none !important;
    height: 325px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.program-card-parent:nth-child(even)>.program-card {
    background: #C6DAFA !important;
}

.program-icon-container {
    width: 144px;
    height: 144px;
    background-color: #FFF;
    border-radius: 144px;
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.program-icon-container>img {
    width: 80px;
    height: 80px;
}

.card-content {
    margin-top: 36px;
    padding: 5px;
    text-align: center;
    color: #132433;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Open Sans;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 147.368% */
}