.root {
    margin-top: 24px;
    margin-bottom: 24px;
}

.link {
    padding: 0 8px;
    color: #451563;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 15.648px;
    cursor: pointer;
    /* 65.201% */
}
.verticle-line{
    height: 20px;
    padding: 1px;
    background-color: #451563;
    margin: 0;
    opacity: 1;
    border-radius: 10px;
}

.active-link{
    color: rgba(69, 21, 99, 0.20);
}