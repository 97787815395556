.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.primary-background {
  background-color: #FAF9F7;
  border-radius: 20px;
  height: auto;
  padding: 24px;
}

.react-calendar {
  border-radius: 12.733px !important;
  background: #FFF !important;
  width: 100% !important;
  overflow: hidden;
  padding: 24px;
  border: none !important;
}

.react-calendar__navigation__arrow {
  display: none !important;
}

.react-calendar .month-label {
  color: #5570F1;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 33.955px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  /* 46.858px */
}

.react-calendar__navigation button {
  pointer-events: none;
  touch-action: none;
}

.react-calendar .year-label {
  color: #000;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 33.955px;
  font-style: normal;
  font-weight: 600;
  line-height: 138%;
  /* 46.858px */
}

.react-calendar__tile--now {
  background-color: #FFF !important;
}

.react-calendar__month-view__weekdays {
  display: flex;
  padding-right: 0px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  color: #74A0FF !important;
  text-align: center !important;
  font-family: Roboto !important;
  font-size: 19.545px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none !important;
}

.react-calendar__tile {
  padding: 0px !important;
  background-color: #FFF !important;
  background: #FFF !important;
  pointer-events: none;
  touch-action: none;
}

.react-calendar__tile abbr {
  padding: 8px;
  display: inline-flex;
  margin: 2px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #020202;
  text-align: center;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.react-calendar__tile--hasActive abbr {
  background-color: #00FF1A !important;
  background: #00FF1A !important;
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.react-calendar__navigation__label,
.react-calendar__navigation__label:hover {
  background: none;
  background-color: inherit !important;
  cursor: default !important;
}

.qr-card {
  border-radius: 20px;
  overflow: hidden;
}

.qr-content {
  overflow: hidden;
  border-radius: 20px;
  background: #FFF;
  display: flex;
  flex-direction: column;
}

.qr-card .qr-title {
  color: #A041EA;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: 1.026px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.qr-border {
  border-top: 1px solid #A041EA;
  border-left: 1px solid #A041EA;
  border-right: 1px solid #A041EA;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  overflow: hidden;
}

.qr-divider {
  height: 2px;
  margin: auto;
  width: 70%;
  border-radius: 50%;
  background: linear-gradient(90deg, #FFFFFF, #5570F1, #5570F1, #FFFFFF);
}

.qr-content-date-time {
  color: #9F53CF;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.qr-content-date-time span {
  color: #4E4E4E;
  font-weight: 600;
}

.qr-content-sub-title {
  color: #4E4E4E;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}

.bottom-background-img {
  position: relative;
  left: -24px;
  width: calc(100% + 48px);
  overflow: hidden;
  background-image: url("./assets/image 28.png");
  bottom: -24px;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 221px;
  background-size: cover;
}

.faq-rounded-card>div {
  background: #D2E0F7 !important;
  border-radius: 50px;
  background: #D2E0F7 !important;
  border: none !important;
  height: 90px !important;
  width: 90px !important;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: center;
}

.faq-rounded-card:nth-child(even)>div {
  background: #E1C3F4 !important;
}

.faq-rounded-card-title {
  color: #000;
  text-align: center;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.faq-border-divider {
  position: absolute;
  width: 95%;
  border-top: 2px dashed #9F53CF;
  top: 31%;
  left: 10px;
}

.faq-title {
  color: #4E4E4E;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 64.62px;
  /* 161.549% */
  letter-spacing: 1.026px;
}

.accordion-item {
  overflow: hidden;
  border: none !important;
  padding: 14px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

.accordion-button::after {
  background: none !important;
  content: "+" !important;
  font-size: 22px;
  position: relative;
  background-color: #F7F7FF !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-button:not(.collapsed)::after {
  background: none !important;
  content: "_" !important;
  font-size: 22px;
  top: 10px;
  border-radius: 5px;
  position: relative;
}

.accordion-button {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
}

.accordion-body {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.accordion-button {
  background-color: #fff !important;
  background: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.footer-text {
  color: #FCDEA2;

  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.766px;
  /* 80.518% */
}

.email-text-box {
  border-radius: 60.12px;
  border: 1.718px solid #A6A6A6;
  max-width: 500px;
  display: flex;
}

.email-text-box input {
  background-color: inherit;
  border: none;
  flex: 1;
  left: 15px;
  top: -2px;
  position: relative;
  outline: none;
  box-shadow: none;
  border-radius: 60.12px;
  color: #FCDEA2;
}

.email-text-box button {
  background-color: #FCDEA2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 2px;
  height: 35px;
  width: 35px;
  border-radius: 35px;
}

.footer-bottom-text {
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.766px;
  /* 161.036% */
}

.social-icon {
  border: none;
  margin: 4px;
  border-radius: 35px;
  background-color: inherit;
  background: none;
}
.social-icon img{
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 1399px) {
  .qr-card .qr-title {
    font-size: 18px;
  }

  .qr-border img {
    height: 100px;
    width: 100px;
  }

  .qr-content-date-time {
    font-size: 14px;
  }

  .qr-content-date-time span {
    font-size: 14px;
  }

  .qr-content-sub-title {
    font-size: 12px;
  }
}